import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProject } from '../../../observable/model/addProject';
import { Branch } from '../../../observable/model/branch';
import { ProjectRepository } from '../../../repository/project-repository';
import { ToastRepository } from '../../../repository/toast-repository';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.less']
})
export class AddProjectComponent implements OnInit {
  selectedCompanies: any[] = [];
	companies: any[] = [];
  companiesNames = [];
  project = new AddProject();
  branchList: Branch[] = [];
  branchUserList = [];
  selectedIcon = null;
  title: String;
  isEdit = false;
  isInactive: boolean;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private projectRepository: ProjectRepository,
    public toastRepository: ToastRepository
  ) {
    this.project.inspectors = [];
  }

  ngOnInit(): void {
    this.init();
    this.title = this.isEdit ? 'Edit Project' : 'Add Project';
  	this.companiesNames.forEach((c, i) => {
			this.companies.push({ id: i, name: c });
		});
    if (this.isEdit) {
      if(this.project.emails != '' && this.project.emails != null){
        console.log('456',this.project.emails);
        this.project.emails.split(',').forEach((c, i) => {
          this.selectedCompanies.push({name: c });
        });
      }
      this.handleEditProject();
    } else {
      this.project.label = 'fff2994a';
    }
     
  }

  addTagFn = (name: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailPattern.test(name)) {
      return { name: name, tag: true }; 
    } else {
      this.toastRepository.showDanger('Please enter the correct email format.');
      return null;  
    }
  }
  
  

  handleEditProject(): void {
    if (this.project.branch) {
      const branch = new Branch();
      branch.id = this.project.branch;
      branch.name = this.project.branchName;
      this.branchList.push(branch);
  
      const selectedBranch = this.branchList.find(branch => branch.id === this.project.branch);
      if (selectedBranch) {
        this.project.branchName = selectedBranch.name;
      }
    }
  }

  hexList = [
    { label: 'fff2994a', icon: 'building' },
    { label: 'ff2f80ed', icon: 'construction' },
    { label: 'ff9b51e0', icon: 'house' },
    { label: 'ff219653', icon: 'bank' },
  ];

  cancel(): void {
    this.ngbActiveModal.close();
  }

  init(): void {
    this.getBranchList();
    this.getBranchUser();
  }

  getBranchList(): void {
    this.projectRepository.getBranch(this.project.id).subscribe(result => {
      this.branchList = result;
      
      if (this.isEdit && this.project.branch) {
        
        const selectedBranch = this.branchList.find(branch => branch.id === this.project.branch);
        if (selectedBranch) {
          this.project.branch = selectedBranch.id;
          this.project.branchName = selectedBranch.name;
        }
      } else {
        if (this.branchList.length === 1) {
          this.project.branch = this.branchList[0].id;
          this.getBranchUser(true); 
        }
      }
    });
  }

  selectProject(item): void {
    this.selectedIcon = item.label;
    this.project.label = item.label;
  }

  getCheckBranchValue(event: any): void {
    const selectedBranch = this.branchList.find(branch => branch.id === event);
    if (selectedBranch) {
      this.project.branch = selectedBranch.id;
      this.project.branchName = selectedBranch.name;
    }
    this.getBranchUser();
  }

  getBranchUser(selectAll: boolean = false): void {
    if (!this.project.branch) {
      return;
    }
    this.projectRepository.getBranchUserList(this.project.branch).subscribe(res => {
      if (res) {
        this.branchUserList = Object.keys(res).map(key => ({
          key: key,
          value: res[key],
          checked: selectAll || (this.isEdit ? this.project.inspectors.includes(key) : false),
          disabled: selectAll 
        }));

        if (selectAll) {
          this.project.inspectors = this.branchUserList.map(user => user.key);
        }
      }
    });
  }

  selectBranchBox(item): void {
    if (item.checked) {
      this.project.inspectors.push(item.key);
    } else {
      const index = this.project.inspectors.indexOf(item.key);
      if (index > -1) {
        this.project.inspectors.splice(index, 1);
      }
    }
  }

  addProject(): void {
    if (!this.project.name || !this.project.client || !this.project.label || !this.project.address || !this.project.branch) {
      this.toastRepository.showDanger('Please enter the required information');
      return;
    }
    this.projectRepository.saveProject(this.project).subscribe(result => {
      if (result) {
        this.isEdit ? this.toastRepository.showSuccess('Edit project successfully.') : this.toastRepository.showSuccess('Added project successfully.');
      } else {
        this.toastRepository.showDanger('Failed to add project.');
      }
      this.cancel();
    });
  }

  unarchive(state: string): void {
    this.projectRepository.archive(this.project.id).subscribe(res => {
      if (res) {
        state === 'ACTIVATED' ? this.toastRepository.showSuccess('Archive this project successfully.') : this.toastRepository.showSuccess('Unarchive this project successfully.');
      } else {
        this.toastRepository.showDanger(`This Project is under ${this.project.branchName} Branch, an Inactive Branch. Please make ${this.project.branchName} Active in Branch settings to Unarchive this project.`);
      }
      this.cancel();
    });
  }
  
  capitalize(value: string): string {
    if (!value) return value;
    return value.replace(/( |^)[a-zA-Z]/g, (L) => L.toUpperCase());
  }

  selectEmail(event: any){
    this.project.emails = this.selectedCompanies.
    map(company => company.name).join(',');
  }

}

<div class="email-box">
    <div class="modal-header">
        <h4 class="modal-title">Update Email</h4>
    </div>
    <div class="modal-body">
        <div class="form">

            <!-- <h4 *ngIf="account != null">Verification email has been sent to </h4>
            <h3 *ngIf="account != null" [innerText]="account"></h3> -->

            <!-- 第一次进入 email 文本 -->
            <h4>Verification email has been sent to</h4>
            <!-- 第二次进入 email 文本 -->
            <!-- <h4>Verification email has been sent to the new email</h4> -->

            <h3>{{this.account}}</h3>
            <div class="subtitle mb-40"> Enter the verification code below</div>
            <div class="validation-box">
                <input type="text" #input0 maxlength="1" [disabled]="(current|async) != 0"
                    [ngClass]="{'active': (current|async) == 0}" [(ngModel)]="num1"
                    (ngModelChange)="changeModel(num1,0)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">
                <input type="text" #input1 maxlength="1" [disabled]="(current|async) != 1"
                    [ngClass]="{'active': (current|async) == 1}" [(ngModel)]="num2"
                    (ngModelChange)="changeModel(num2,1)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">
                <input type="text" #input2 maxlength="1" [disabled]="(current|async) != 2"
                    [ngClass]="{'active': (current|async) == 2}" [(ngModel)]="num3"
                    (ngModelChange)="changeModel(num3,2)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">

                <input type="text" #input3 maxlength="1" [disabled]="(current|async) != 3"
                    [ngClass]="{'active': (current|async) == 3}" [(ngModel)]="num4"
                    (ngModelChange)="changeModel(num4,3)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">
                <input type="text" #input4 maxlength="1" [disabled]="(current|async) != 4"
                    [ngClass]="{'active': (current|async) == 4}" [(ngModel)]="num5"
                    (ngModelChange)="changeModel(num5,4)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">

                    <input type="text" #input5 maxlength="1" [disabled]="(current|async) != 5"
                    [ngClass]="{'active': (current|async) == 5}" [(ngModel)]="num6"
                    (ngModelChange)="changeModel(num6,5)" [ngModelOptions]="{updateOn:'change'}" placeholder="-">

                    <!-- <input type="text" #input5 maxlength="1" [disabled]="(current|async) != 5"
                    [ngClass]="{'active': (current|async) == 5}" [(ngModel)]="num6"
                    (ngModelChange)="changeModel(num6,5)" [ngModelOptions]="{updateOn:'change'}" placeholder="-"> -->
            </div>
            <div class="form-button mb-24">
                <button class="btn btn-o" (click)="verify()">Verify email
                </button>
            </div>
            <div class="code-tip"><a (click)="cancel()" href="javascript:void(0)">Cancel</a> <span>
                    | </span>
                <a href="javascript:void(0)" (click)="resend()"
                    [ngClass]="{'disabled': !canResend}"><span>{{displayText}}</span></a>
            </div>
        </div>
    </div>
</div>
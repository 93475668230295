import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutSettingComponent } from '../../../common/layout-setting/layout-setting.component';
import { Profile } from '../../../observable/model/user';
import { ProfileRepository } from '../../../repository/profile-repository';
import { ToastRepository } from '../../../repository/toast-repository';
import { EmailCodeComponent } from '../../modal/email-code/email-code.component';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

    profile = new Profile();

    constructor(
        private ngbModal: NgbModal,
        private profileRepository: ProfileRepository,
        private toastRepository: ToastRepository,
        private layoutSettingComponent: LayoutSettingComponent
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        this.profileRepository.info().subscribe(result => {
            this.profile = result;
            this.layoutSettingComponent.companyName = this.profile.companyName;
        });
    }

    save(): void {
        if(this.profile.password && (this.profile.password.length < 8 || this.profile.password.length > 32 ) ){
            this.toastRepository.showDanger('Password length should be between 8 and 32 characters.')
            return
        }
        this.profileRepository.save(this.profile).subscribe(res => {
            this.layoutSettingComponent.companyName = this.profile.companyName;
            this.toastRepository.showSuccess('Profile updated successfully.');
        });
    }

    editEmail(): void {
        const ngbModalRef = this.ngbModal.open(EmailCodeComponent, {backdrop: 'static', keyboard: true, size: 'big'});
        ngbModalRef.componentInstance.account = this.profile.email;
        ngbModalRef.result.then(result => {
            this.init();
        });
    }
    capitalizeFirst(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      capitalize(value: string): string {
        if (!value) return value;
        return value.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());  
    }
}

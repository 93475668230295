<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">
            <div class="text-box">
                <form class="form" name="inviteAgree" [ngxFormValidator]="validatorConfig">
                    <h2 class="title">Join your team now.</h2>
                    <div class="form-two">
                        <div class="form-control-group form-icon">
<!--                            <label for="firstName">First name</label>-->
                            <input type="text" id="firstName" name="firstName" [(ngModel)]="teamUserPassword.firstName"
                            (ngModelChange)="teamUserPassword.firstName = capitalizeFirst(teamUserPassword.firstName)" 
                                required maxlength="50" placeholder="Enter first name">
                            <span class="icon icon-16 icon-user icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
<!--                            <label for="lastName">Last name</label>-->
                            <input type="text" id="lastName" name="lastName" [(ngModel)]="teamUserPassword.lastName"
                            (ngModelChange)="teamUserPassword.lastName = capitalizeFirst(teamUserPassword.lastName)" 
                                required maxlength="50" placeholder="Enter last name">
                            <span class="icon icon-16 icon-user icon-first-pic"></span>
                        </div>
                    </div>
               
                    <div class="form-control-group form-icon">
<!--                        <label for="">Password</label>-->
                        <input type="password" name="password"  [(ngModel)]="teamUserPassword.password" minlength="8" maxlength="32" *ngIf="!showPassword"
                               required placeholder="Enter password">
                        <input type="text" name="password" [(ngModel)]="teamUserPassword.password" minlength="8" maxlength="32"  *ngIf="showPassword"
                               required placeholder="Enter password">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showPassword" (click)="changePassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showPassword" (click)="changePassword('hide')"></span>
                    </div>
                    <div class="form-control-group form-icon">
<!--                        <label for="">Confirm password</label>-->
<!--                        <input type="password" id="confirmPassword" [(ngModel)]="teamUserPassword.confirmPassword"-->
<!--                            name="confirmPassword" required [ngxUniqueCheck]="passwordEqual"-->
<!--                            placeholder="Enter password again">-->
                        <input type="password" name="confirmPassword" [(ngModel)]="teamUserPassword.confirmPassword" minlength="8" maxlength="32"
                               required [ngxUniqueCheck]="passwordEqual" *ngIf="!showConfirmPassword"
                               placeholder="Enter password again">
                        <input type="text" name="confirmPassword"  [(ngModel)]="teamUserPassword.confirmPassword" minlength="8" maxlength="32"
                               required [ngxUniqueCheck]="passwordEqual" *ngIf="showConfirmPassword"
                               placeholder="Enter password again">
                        <span class="icon icon-16 icon-password icon-first-pic"></span>
                        <span class="icon icon-16 icon-eye-close icon-right-pic" [hidden]="showConfirmPassword" (click)="changeConfirmPassword('show')"></span>
                        <span class="icon icon-16 icon-eye-open icon-right-pic" [hidden]="!showConfirmPassword" (click)="changeConfirmPassword('hide')"></span>
                    </div>

                    <div class="check">
                        <input name="trcheck" required type="checkbox" [(ngModel)]="teamUserPassword.trcheck" id="check">
                        <div class="tip">
                            <label for="check">I agree with</label>&nbsp;<span (click)="openTerms()"> terms & conditions</span>&nbsp;&&nbsp;<span (click)="openPolicy()" class="privacy"> privacy policy</span>
                        </div>
                    </div>
                    <button class="btn btn-o btn-block" (ngxFormSubmit)="signUp()">Sign In</button>
                    <div class="bottom-tip">Already have an account? <a [routerLink]="['/login']"
                            href="javascript:void(0)">Log in</a></div>

                     <div class="line mt-20 mb-10"></div> 
                     <p>Your Scafflinq account is now active. Please download the Scafflinq app on your phone.</p>
                     <div class="btn-main">
                        <div class="btn-a">
                            <a href="https://apps.apple.com/app/id6444101388" target="_blank">Download on App Store</a>
                        </div>
                        <div class="btn-a">
                            <a href="https://play.google.com/store/apps/details?id=com.constructiontag.scafflinq" target="_blank">Download on Play Store</a>
                        </div>
                     </div>
                </form>
            </div>
        </div>
    </div>
</div>

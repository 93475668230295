<div class="admin-wrapper">
    <div class="admin">
        <div class="admin-left">
            <div class="logo"></div>
            <div class="bg-box"></div>
        </div>
        <div class="admin-right">


            <div class="text-box " [hidden]="isComplete">
                <div class="switch">
                    <div class="line" [ngClass]="{'active': 3 >= switchStatus}"></div>
                    <div class="line" [ngClass]="{'active': 3 >= switchStatus && switchStatus > 1}"></div>
                    <div class="line" [ngClass]="{'active': switchStatus == 3}"></div>
                </div>
                <div class="back-top" [hidden]="switchStatus!=1" style="opacity: 0;">
                    <span class="icon icon-24 icon-arrow-back"></span>
                    {{fromPage == 'download' ? 'App Download' : fromPage == 'invite' ? 'Invite Email' : 'Scafftag Confirmation'}}
                </div>
                <div class="back-top" (click)="backDetail()" [hidden]="switchStatus!=2">
                    <span class="icon icon-24 icon-arrow-back"></span>
                    Payment Details
                </div>
                <div class="back-top" (click)="backDetail()" [hidden]="switchStatus!=3">
                    <span class="icon icon-24 icon-arrow-back"></span>
                    Address Detail
                </div>
                <div class="title" [hidden]="switchStatus!=1">Payment Detail</div>
                <div class="title" [hidden]="switchStatus!=2">Address Detail</div>
                <div class="title" [hidden]="switchStatus!=3">Contact Detail</div>
                <div *ngIf="switchStatus == 1" class="subtitle">Enter your credit card details below. We’ll bill you monthly at the end of each
                    month only for what you use.</div>
                <div *ngIf="switchStatus == 2" class="subtitle">Enter your address details below. We’ll bill you monthly at the end of each month only for what you use.</div>
                <div *ngIf="switchStatus == 3" class="subtitle">Enter the details of your billing contact. </div>
                <div *ngIf="switchStatus == 1" class="cost">
                    <div class="amount">$0.40</div>
                    <div class="perweek">per week</div>
                </div>
                <div *ngIf="switchStatus == 1" class="erect">Per erect scaffold</div>
                <div class="powered-by-stripe" [hidden]="switchStatus!=1">
                    <img src="assets/img/powered-by-stripe.png">
                </div>
                <div class="banner">
                    <img src="assets/img/card-image.png " width="40px" alt="">
                    <div>Scaffold Tags are free to order. Your first 5 erected scaffolds will be $0.00 per week.</div>
                </div>
                <div class="stripe-body">
                    <div class="form modal-form" [hidden]="switchStatus>1">
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Name on card</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.name " (input)="checkFirstBtn()" placeholder="Name on card" 
                            (ngModelChange)="billing.name = capitalize(billing.name)"
                            />
                            <span class="icon icon-16 icon-user icon-first-pic"></span>
                        </div>
                        <div class="form-group form-icon">
                            <!--                            <label class="control-label">Card number </label>-->
                            <div class="card-stripe" [ngClass]="{'card-error': numberError}">
                                <div id="card-number-element"></div>
                            </div>
                            <span class="error" *ngIf="numberError">{{ numberError }}</span>
                            <span class="icon icon-16 icon-card icon-first-pic"></span>
                        </div>
                        <div class="form-two">
                            <div class="form-group form-icon">
                                <!--                                <label class="control-label">Expire date</label>-->
                                <div class="card-stripe" [ngClass]="{'card-error': dateError}">
                                    <div id="card-expiry-element"></div>
                                </div>
                                <span class="error" *ngIf="dateError">{{ dateError }}</span>
                                <span class="icon icon-16 icon-calendar icon-first-pic"></span>
                            </div>
                            <div class="form-group form-icon">
                                <!--                                <label class="control-label">CVV</label>-->
                                <div class="card-stripe" [ngClass]="{'card-error': cvcError}">
                                    <div id="card-cvc-element"></div>
                                </div>
                                <span class="error" *ngIf="cvcError">{{ cvcError }}</span>
                                <span class="icon icon-16 icon-password icon-first-pic"></span>
                            </div>
                        </div>

                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Company Name</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.companyName" (input)="checkFirstBtn()" placeholder="Company name" 
                            (ngModelChange)="billing.companyName = capitalize(billing.companyName)"
                            />
                            <span class="icon icon-16 icon-building-1 icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">ABN</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.abn" (input)="changeAbn()" (input)="checkFirstBtn()" placeholder="ABN/Tax ID" 
                            (ngModelChange)="billing.abn = capitalize(billing.abn)"
                            />
                            <span class="icon icon-16 icon-hash icon-first-pic"></span>
                        </div>
                    </div>
                    <div class="form modal-form" *ngIf="switchStatus==2">
                        <div class="form-control-group  form-icon">
                            <!--                            <label class="control-label">Address</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.address" (input)="checkBtn()" placeholder="Street number & street name" 
                            (ngModelChange)="billing.address = capitalize(billing.address)"
                            />
                            <span class="icon icon-16 icon-map icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">City</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.city" (input)="checkBtn()" placeholder="Suburb/town" 
                            (ngModelChange)="billing.city = capitalize(billing.city)"
                            />
                            <span class="icon icon-16 icon-home icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Country</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.country" (input)="checkBtn()" placeholder="Country"
                            (ngModelChange)="billing.country = capitalize(billing.country)"
                            />
                            <span class="icon icon-16 icon-globe icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Postcode</label>-->
                            <input type="text" class="placeholder-input" [(ngModel)]="billing.postcode" (input)="checkBtn()" placeholder="Postcode/zip code" 
                            (ngModelChange)="billing.postcode = capitalizeUpperCase(billing.postcode)"
                            />
                            <span class="icon icon-16 icon-mailbox icon-first-pic"></span>
                        </div>

                    </div>

                    <div class="form modal-form" *ngIf="switchStatus==3">
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Billing contact email</label>-->
                            <input type="text" class="form-control" [(ngModel)]="billing.email" (input)="checkThirdBtn()" placeholder="Billing contact email" />
                            <span class="icon icon-16 icon-email icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Billing contact First Name</label>-->
                            <input type="text" class="form-control" [(ngModel)]="billing.firstName"  (ngModelChange)="billing.firstName = capitalizeFirst(billing.firstName)" (input)="checkThirdBtn()" placeholder="Billing contact first name" />
                            <span class="icon icon-16 icon-user icon-first-pic"></span>
                        </div>
                        <div class="form-control-group form-icon">
                            <!--                            <label class="control-label">Billing contact Last Name</label>-->
                            <input type="text" class="form-control" [(ngModel)]="billing.lastName"  (ngModelChange)="billing.lastName = capitalizeFirst(billing.lastName)"  (input)="checkThirdBtn()" placeholder="Billing contact last name " />
                            <span class="icon icon-16 icon-user icon-first-pic"></span>
                        </div>
                    </div>
                </div>
             
                <div class="form-button">
                    <button class="btn btn-o" [disabled]="loading" (click)="next()">{{switchStatus == 3 ? 'Confirm Details' : 'Next' }}</button>
                </div>
              
            </div>


            <div class="text-box" [hidden]="!isComplete">
                <div class="back-top" (click)="backDetail()">
                    <span class="icon icon-24 icon-arrow-back"></span>
                    Contact details
                </div>
                <div class="title">Confirm Purchase</div>
                <div class="pay">
                    <div class="pay-detail">
                        <span class="first">Subscription</span>
                        <span class="second">Professional plan</span>
                    </div>
                    <div class="pay-detail">
                        <span class="first">Frequency</span>
                        <span class="second">Monthly</span>
                    </div>
                    <div class="line"></div>
                    <div class="pay-detail">
                        <span class="first">Price</span>
                        <span class="second">${{price}}</span>
                    </div>
                    <div class="pay-detail">
                        <span class="first">Tax</span>
                        <span class="second">${{taxPrice}}</span>
                    </div>
                    <div class="pay-detail">
                        <span class="first">Discount</span>
                        <span class="second green-text-color">-$0.00</span>
                    </div>
                    <div class="line"></div>
                    <div class="pay-detail">
                        <span class="first">Total</span>
                        <span class="second fs-18">${{totalPrice}}</span>
                    </div>
                </div>
                <div class="form-two-button">
                    <button class="btn btn-o" [disabled]="loading" (click)="confirmPay()">Confirm the payment</button>
                    <div class="cancel" (click)="cancel()">Cancel</div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="admin-wrapper">
    <div class="left">
        <div class="text-box">
            <div class="sign-logo"><img src="assets/img/download-logo.svg" /></div>
            <div class="description">
                <div>
                    <div class="tip">Download the app now</div>
                    <div class="desc">
                        Get the best scaffolding tag management software now! Scafflinq is currently available on both iOS and Android.
                    </div>
                </div>

                <!-- <div class="img"><img src="assets/img/download.png" alt=""></div> -->
            </div>
            <div class="download-btn">
                <button class="btn btn-white btn-block" (click)="downLoad('ios')"> <img src="assets/img/app-store.png" alt=""></button>
                <button class="btn  btn-default" (click)="downLoad('android')" >  <img src="assets/img/google-play.png" alt=""> </button>
            </div>
            <div class="btn-text" [hidden]="fromPage == 'invite'">
                <span (click)="getStripe()">Download later</span>
            </div>
        </div>
    </div>
    <div class="right">
        <div class="img"><img src="assets/img/download.svg" alt=""></div>
    </div>
</div>




